import React from 'react';
// styles
import clsx from 'clsx';
import styles from './chat-gpt.module.css';
import headerStyles from '../../services/components/header/header.module.css';
// components
import Layout from 'components/layout';
import Footer from 'components/layout/footer';
import Booking from 'components/feedback';
import Wave from 'pages/components/header/wave';
// constants
import { meta } from 'metaData';

const ChatGPT = () => {
  return (
    <Layout navBtnTextColor="var(--purple-color)" metaData={{ main: meta.chatGPT }}>
      <div className={headerStyles.container}>
        <div className={clsx(headerStyles.wrapper, styles.wrapper)}>
          <div className={clsx('inner', styles.contentContainer)}>
            <div className={clsx(headerStyles.content, styles.content)}>
              <h1 className={styles.title}>
                Generate 5 Ideas How Open AI Can Benefit Your Business
              </h1>
            </div>
          </div>

          <Wave color="#ffffff" opacity={0.08} />
        </div>
      </div>
      <div className={clsx('inner', styles.formContainer)}>
        <div className={styles.form}>
          <iframe
            frameBorder="0"
            width="100%"
            height="500px"
            src="https://landbot.online/v3/H-1496116-MOZAOYYLOCCY18XP/index.html"
            sandbox="allow-forms allow-scripts allow-same-origin"
            loading="lazy"
            referrerPolicy="no-referrer"
          ></iframe>
        </div>
      </div>
      <div className={styles.booking}>
        <Booking />
      </div>
      <Footer />
    </Layout>
  );
};

export default ChatGPT;
